<template>
    <div>
        <div class="purpose">真诚协作、及时高效、专业严谨</div>
        <el-calendar v-model="value"></el-calendar>
    </div>
</template>
<script>
export default{
    data(){
        return {
            value: new Date()
        }
    }
}
</script>
<style lang="less">
.purpose{
    text-align: center;
    line-height: 50px;
    font-size: 20px;
    color: #F08D34;
    font-weight: bold;
}
</style>